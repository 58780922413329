var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'unselectable-text':
      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
  }},[_c('draggable',_vm._b({staticClass:"draggable-segment",attrs:{"disabled":!_vm.allows_crud,"group":"segment","handle":".handle-segment"},model:{value:(_vm.testSegments),callback:function ($$v) {_vm.testSegments=$$v},expression:"testSegments"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},[_vm._l((_vm.testSegments),function(segment){return _c('div',{key:segment.id,staticClass:"segment-container",class:{ 'segment-container-min-heigth': _vm.allows_crud }},[_c('div',{staticClass:"title-top-segment"},[(_vm.allows_crud)?_c('div',{staticClass:"question-move px-1 handle-segment",class:{ 'grabbable-cursor': _vm.allows_crud }},[_c('b-icon',{staticClass:"move-icon",attrs:{"icon":"arrows-expand","scale":"1.3"}})],1):_vm._e(),_c('div',{staticClass:"w-100 right-container-segment"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  `${
                    _vm.allows_crud && !segment.is_locked ? 'Editar título' : ''
                  }`
                ),expression:"\n                  `${\n                    allows_crud && !segment.is_locked ? 'Editar título' : ''\n                  }`\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"pl-2 w-100 title-segment rich-text-content",class:{
                  'title-container': _vm.allows_crud && !segment.is_locked,
                },domProps:{"innerHTML":_vm._s(segment.title)},on:{"click":function($event){return _vm.showModalSegment(segment)}}}),_c('div',{staticClass:"ml-0 title-segment",class:{ 'container-buttons-allow-cruds': _vm.allows_crud }},[(
                    !segment.is_locked &&
                    _vm.allows_crud &&
                    _vm.user &&
                    !_vm.user.groups.includes(6)
                  )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Bloquear ${_vm.$getVisibleNames(
                      'evaluations2.testsegment',
                      false,
                      'Segmento'
                    )}`
                  ),expression:"\n                    `Bloquear ${$getVisibleNames(\n                      'evaluations2.testsegment',\n                      false,\n                      'Segmento'\n                    )}`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStateSegment(segment)}}},[_c('b-icon-unlock-fill')],1):_vm._e(),(
                    segment.is_locked &&
                    _vm.allows_crud &&
                    _vm.user &&
                    !_vm.user.groups.includes(6)
                  )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Desbloquear ${_vm.$getVisibleNames(
                      'evaluations2.testsegment',
                      false,
                      'Segmento'
                    )} `
                  ),expression:"\n                    `Desbloquear ${$getVisibleNames(\n                      'evaluations2.testsegment',\n                      false,\n                      'Segmento'\n                    )} `\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStateSegment(segment)}}},[_c('b-icon-lock-fill')],1):_vm._e(),(_vm.allows_crud && !segment.is_locked)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Eliminar ${_vm.$getVisibleNames(
                      'evaluations2.testsegment',
                      false,
                      'Segmento'
                    )}`
                  ),expression:"\n                    `Eliminar ${$getVisibleNames(\n                      'evaluations2.testsegment',\n                      false,\n                      'Segmento'\n                    )}`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"delete-btn",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.askForDeleteSegment(segment.id)}}},[_c('b-icon-trash')],1):_vm._e()],1)]),_c('b-modal',{attrs:{"id":`edit-title-segment-modal-${segment.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":"Redactar enunciado del título.","size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":segment,"Text":segment.title,"hide_title":true,"permit_blank":true},on:{"save":_vm.patchSegmentTitle,"close":function($event){return _vm.$bvModal.hide(
                    `edit-title-segment-modal-${segment.id}-${_vm.instrument_id}-${_vm.container_instrument_id}`
                  )}}})],1)],1),_c('QuestionsContainer',{attrs:{"container_instrument_id":_vm.container_instrument_id,"isLoading":_vm.is_loading,"segment_id":segment.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"instrument_id":_vm.instrument_id,"allows_crud":_vm.allows_crud && !segment.is_locked,"preview_evaluation":_vm.preview_evaluation,"checked_score":_vm.checked_score,"checked_weightning":_vm.checked_weightning,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"user_id":_vm.user_id,"evaluation_situations":_vm.evaluation_situations,"closedEvaluationScore":_vm.closedEvaluationScore,"is_published":_vm.is_published}})],1)})],2)],1),_c('div',{staticClass:"container-buttons"},[(
        _vm.allows_crud &&
        _vm.test &&
        !_vm.test.segment_creation_is_locked &&
        (!_vm.evaluationSituation ||
          (_vm.evaluationSituation &&
            ![
              'duoc_ejecucion_practica_test',
              'duoc_entrega_de_encargo_test',
              'duoc_presentacion_test',
            ].includes(_vm.evaluationSituation.internal_use_id)))
      )?_c('b-button',{attrs:{"variant":"info","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
          `modal-created-segment-${_vm.instrument_id}-${_vm.container_instrument_id}`
        )}}},[_vm._v(" + Crear "+_vm._s(_vm.$getVisibleNames("evaluations2.testsegment", false, "Segmento"))+" ")]):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-created-segment-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":`Crear ${_vm.$getVisibleNames(
      'evaluations2.testsegment',
      false,
      'Segmento'
    )}`,"hide-footer":"","size":"lg"}},[_c('NewRichTextEditor',{attrs:{"Text":'',"hide_title":true,"permit_blank":true},on:{"save":_vm.createSegment,"close":function($event){return _vm.$bvModal.hide(
          `modal-created-segment-${_vm.instrument_id}-${_vm.container_instrument_id}`
        )}}})],1),_c('QuestionsContainer',{attrs:{"container_instrument_id":_vm.container_instrument_id,"isLoading":_vm.is_loading,"instrument_id":_vm.instrument_id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"allows_crud":_vm.allows_crud,"preview_evaluation":_vm.preview_evaluation,"checked_score":_vm.checked_score,"checked_weightning":_vm.checked_weightning,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"user_id":_vm.user_id,"evaluation_situations":_vm.evaluation_situations,"closedEvaluationScore":_vm.closedEvaluationScore,"is_published":_vm.is_published}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }